import React from 'react';
import { Link } from 'gatsby';
import { Icon } from '@iconify/react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Menu from '../components/navigation';
import FullImage from '../components/full-image';
import Dots from '../components/dots';
// import Projects from '../components/projects';

// import image1 from '../images/atelier-consulting.jpg';
// import image1x2 from '../images/atelier-consulting@2x.jpg';

/**
 * Page
 */
const AtelierPage = () => {
	return (
		<Layout page="atelier" image="atelier">
			<SEO title="" />
			<Menu selected="atelier" />
			<div className="page-width align-center spacing-normal spacing-huge-top">
				<p className="text-subheadline text-subheadline--bold">
					HALLO, ICH BIN VIKTORIA
				</p>
				<p className="spacing-huge-bottom">
					Ich liebe, was ich tue! Und so stecken in allen meinen
					Projekten, neben meinen fachlichen Fähigkeiten, meine
					Passion für Design und ganz viel Herzblut.
				</p>
				<p className="text-subheadline text-subheadline--bold">
					INTERESSANT ZU WISSEN
				</p>
				<div className="split">
					<div>
						<Icon icon="vb:atelier0" height="auto" />
						<p>
							Ich wurde in der UDSSR geboren und habe die ersten
							20 Jahre meines Lebens in der Hauptstadt von Estland
							verbracht
						</p>
					</div>
					<div>
						<Icon icon="vb:atelier1" height="auto" />
						<p>
							2006 ausgewandert nach Deutschland zum Studium an
							der Fachhochschule Trier
						</p>
					</div>
					<div>
						<Icon icon="vb:atelier2" height="auto" />
						<p>
							Ich habe Innenarchitektur und anschließend
							Kommunikationsdesign studiert
						</p>
					</div>
					<div>
						<Icon icon="vb:atelier3" height="auto" />
						<p>
							Seit 2017 bin ich selbständige
							Kommunikationsdesignerin mit Sitz in Saarbrücken
						</p>
					</div>
					<div>
						<Icon icon="vb:atelier4" height="auto" />
						<p>Meine Muttersprache ist Russisch.</p>
					</div>
					<div>
						<Icon icon="vb:atelier5" height="auto" />
						<p>
							Seit 2017 bin ich Kaffeesüchtig. Seit März 2020
							besonders schwerwiegend ;)
						</p>
					</div>
				</div>

				<Dots />

				{/* <div className="split atelier-consulting">
					<div>
						<p className="text-subheadline text-subheadline--bold align-left">
							WENN STRATEGIE UND DESIGN ZUSAMMENKOMMEN
						</p>
						<p className="align-left">
							Dann entsteht eine sehr nützliche Kooperation.
							Zusammen mit der{' '}
							<a
								href="https://www.gral-beraterteam.de"
								target="_blank"
							>
								gral Strategieberatung
							</a>{' '}
							betreuen wir Kunden von der Gründung bis zum Aufbau
							der Marke. Da ein gelungenes Corporate Design auf
							einer durchdachten Unternehmensstrategie basiert,
							ermöglicht die kooperation die Umsetzung in beiden
							Bereichen. Unsere gemeinsame Projekte werden im
							Rahmen unserer Bürogemeinschaft auf dem Rotenbühl in
							Saarbrücken um die Ecke vom Ilseplatz betreut.
						</p>
					</div>
					<div>
						<img
							alt=""
							src={image1}
							srcSet={image1 + ', ' + image1x2 + ' 2x'}
						/>
					</div>
				</div> */}

				{/* <p className="text-huge text-huge--bold spacing-huge-top">
					GEMEINSAMEN PROJEKTE:
				</p> */}
			</div>

			{/* <Projects /> */}

			<div className="atelier-footer">
				<FullImage image="atelier-footer" />
				<div className="contact">
					<Link to="/kontakt/" className="button">
						<Icon icon="vb:send" />
						Anfrage Senden
					</Link>
				</div>
			</div>
		</Layout>
	);
};

export default AtelierPage;
